import React from 'react';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Catalog from '../components/organisms/Catalog/Catalog';


const SearchTemplate = props => {
  const { pageContext, errors } = props;
  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {pageContext && (
        <Seo
          title={pageContext.metaTitle || ''}
          description={pageContext.meta_description || ''}
        />
      )}
      <Catalog isSearch={true} landingSearch={true} searchData={pageContext} />
    </Layout>
  );
};

export default SearchTemplate;
